import { MaterialIcon } from '@dsny/dsny-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AddNewTrackButton, AddNewTrackWrapper } from './AddNewTrack.styles';

interface AddNewTrackProps {
  hasComparisonOn: boolean;
  setShowEnterpriseLimit: (value: boolean) => void;
  setShowEnterpriseExtend: (value: boolean) => void;
}

const AddNewTrack: React.FC<AddNewTrackProps> = ({
  hasComparisonOn,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setShowEnterpriseLimit,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setShowEnterpriseExtend,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/* Add New Track */}
      <AddNewTrackWrapper hasComparisonOn={hasComparisonOn}>
        <AddNewTrackButton
          setting="mtr"
          fontSize="14px"
          type="button"
          onClick={() => navigate('/payment/multiasset')}
          // onClick={() => setShowEnterpriseLimit(true)} // TODO: enterprise implementation with API
          // onClick={() => setShowEnterpriseExtend(true)} // TODO: enterprise implementation with API - move to TrackingCard
        >
          <MaterialIcon name="Add" />
          &nbsp;{t('DASHBOARD_ADD_NEW_TRACK_BUTTON')}
        </AddNewTrackButton>
      </AddNewTrackWrapper>
    </>
  );
};

export default AddNewTrack;
