import { AttachmentTile, Text } from '@dsny/dsny-component-library';
import React from 'react';
import theme from 'src/styles/theme';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { format } from 'date-fns-tz';
import {
  convertDateToUTC,
  formatTitleAndArtistWithVersion,
} from 'src/utils/Formatter';
import { isCasterReleaseSong } from 'src/utils/VerifyTrackStatus';
import {
  DesktopWrapper,
  MobileWrapper,
  TrackListWrapper,
} from './UploadedTrackList.styles';
import { AddTrackList } from '../../MultiAsset';

type UploadedTrackProps = {
  deleteSong: (id: string, isRelease: boolean) => void;
};

const UploadedTrackList: React.FC<UploadedTrackProps> = ({ deleteSong }) => {
  const { t } = useTranslation();

  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { tracks }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  const { selectPackage } = useSelector((state: RootState) => ({
    selectPackage: state.selectPackage,
  }));

  // Format date to display
  const formatDateToDisplay = (dateToFormat: Date, dateTextformat: string) => {
    return format(convertDateToUTC(new Date(dateToFormat)), dateTextformat);
  };

  // Getting format date template
  const getFormatDateTemplate = (isMobile: boolean) => {
    return isMobile ? 'MM/dd/yy' : 'dd MMMM, yyyy';
  };

  // Handling display text
  const getDisplayText = (
    startDate: string,
    endDate: string,
    isMobile: boolean
  ) => {
    return isMobile
      ? `${startDate} - ${endDate}`
      : `${t('SUMMARY_TRACKING_PERIOD')}: ${startDate} - ${endDate}`;
  };

  // Handling expire date
  const getExpireDate = (startTrackingDate: Date) => {
    const startingDate = new Date(startTrackingDate) ?? new Date(); // Get the current date
    const remainingDays = selectedSong?.selected_tracking_period?.remainingDays; // Get the remaining days from selectedSong
    const durationInMonths = selectPackage.selectedPackage?.plan?.duration || 0; // Get the duration of package selection

    const extensionExpireDate = startingDate;

    const days =
      remainingDays !== undefined && remainingDays > 0 ? remainingDays : 0;

    extensionExpireDate.setDate(startingDate.getDate() + days);

    extensionExpireDate.setMonth(
      extensionExpireDate.getMonth() + durationInMonths
    );

    return extensionExpireDate;
  };

  // Handling subtext
  const getSubText = (startTrackingDate: Date, isMobile: boolean) => {
    const dateformat = getFormatDateTemplate(isMobile);

    const formatedStartDate = formatDateToDisplay(
      startTrackingDate || new Date(),
      dateformat
    );

    const expireDate = getExpireDate(startTrackingDate || new Date());

    const formattedExpireDate = formatDateToDisplay(
      expireDate || new Date(),
      dateformat
    );

    return getDisplayText(formatedStartDate, formattedExpireDate, isMobile);
  };

  // Handling extention subtext
  const getExtentionSubText = (isMobile: boolean) => {
    const dateformat = getFormatDateTemplate(isMobile);
    const currentDate = new Date(); // Get the current date
    const remainingDays = selectedSong?.selected_tracking_period?.remainingDays; // Get the remaining days from selectedSong
    const durationInMonths = selectPackage.selectedPackage?.plan?.duration || 0; // Get the duration of package selection

    const extensionExpireDate = new Date();

    const days =
      remainingDays !== undefined && remainingDays > 0 ? remainingDays : 0;

    extensionExpireDate.setDate(currentDate.getDate() + days);

    const startDate = new Date(extensionExpireDate);

    extensionExpireDate.setMonth(
      extensionExpireDate.getMonth() + durationInMonths
    );

    const formattedStartDate = formatDateToDisplay(startDate, dateformat);
    const formattedExtensionExpireDate = formatDateToDisplay(
      extensionExpireDate,
      dateformat
    );

    return getDisplayText(
      formattedStartDate,
      formattedExtensionExpireDate,
      isMobile
    );
  };

  return (
    <>
      {/* Number of uploaded tracks */}
      <Text
        fontSize="16px"
        fontWeight={700}
        color={theme.colors.neutralW20}
        style={{ paddingBottom: '8px' }}
      >
        <Trans
          i18nKey="SUMMARY_UPLODED_TRACK_MULTI_ASSET"
          values={{
            number: selectedSong && tracks.length === 0 ? 1 : tracks.length,
          }}
        />
      </Text>

      {/* Default flow */}
      {/* Users are given the ability to delete songs UNLESS there is only one song uploaded */}
      <TrackListWrapper>
        {tracks.map((track) => {
          return (
            <div key={track.track_id}>
              {/* Desktop view */}
              <DesktopWrapper key={track.track_id}>
                <AttachmentTile
                  setting="neutral"
                  {...{
                    attachment: {
                      id: track.track_id,
                      uploaduuid: track.S3File?.key,
                      filename: track.title,
                      title: formatTitleAndArtistWithVersion(track, true),
                      size: track.file?.size ? Number(track.file.size) : 0,
                      type: track.file?.type ? String(track.file.type) : '', // Convert to string
                    },
                    uploadedFiles: {},
                  }}
                  displayActionIcon={tracks.length > 1}
                  text={`${track.artist} | ${track.title}`}
                  subText={getSubText(
                    track.startTrackingDate || new Date(),
                    false
                  )}
                  onDelete={() =>
                    deleteSong(track.track_id, isCasterReleaseSong(track))
                  }
                  isUploading={false}
                />
              </DesktopWrapper>

              {/* Mobile view */}
              <MobileWrapper key={`mobile${track.track_id}`}>
                <AttachmentTile
                  setting="neutral"
                  {...{
                    attachment: {
                      id: track.track_id,
                      uploaduuid: track.S3File?.key,
                      filename: track.title,
                      title:
                        track.file?.name ||
                        `${track.releaseTrack?.artist} - ${track.releaseTrack?.title} (${track.releaseTrack?.version})`,
                      size: track.file?.size ? Number(track.file.size) : 0,
                      type: track.file?.type ? String(track.file.type) : '', // Convert to string
                    },
                    uploadedFiles: {},
                  }}
                  displayActionIcon={tracks.length > 1}
                  text={`${track.artist} | ${track.title}`}
                  subText={getSubText(
                    track.startTrackingDate || new Date(),
                    true
                  )}
                  onDelete={() =>
                    deleteSong(track.track_id, isCasterReleaseSong(track))
                  }
                  isUploading={false}
                />
              </MobileWrapper>
            </div>
          );
        })}
      </TrackListWrapper>

      {/* Extension and renewal flow */}
      {tracks.length === 0 && selectedSong && (
        <>
          {/* Desktop view */}
          <DesktopWrapper>
            <AttachmentTile
              setting="neutral"
              {...{
                attachment: {
                  id: selectedSong.song_id,
                  uploaduuid: '',
                  filename: selectedSong.title,
                  title: selectedSong.title || '',
                  size: 0,
                  type: 'audio/wav',
                },
                uploadedFiles: {},
                // eslint-disable-next-line no-console
                onDelete: () => console.log('delete'),
              }}
              displayActionIcon={false}
              text={`${selectedSong.artist} | ${selectedSong.title}`}
              subText={getExtentionSubText(false)}
              isUploading={false}
            />
          </DesktopWrapper>

          {/* Mobile view */}
          <MobileWrapper>
            <AttachmentTile
              setting="neutral"
              {...{
                attachment: {
                  id: selectedSong.song_id,
                  uploaduuid: '',
                  filename: selectedSong.title,
                  title: selectedSong.title || '',
                  size: 0,
                  type: 'audio/wav',
                },
                uploadedFiles: {},
                // eslint-disable-next-line no-console
                onDelete: () => console.log('delete'),
              }}
              displayActionIcon={false}
              text={`${selectedSong.artist} | ${selectedSong.title}`}
              subText={getExtentionSubText(true)}
              isUploading={false}
            />
          </MobileWrapper>
        </>
      )}
    </>
  );
};

export default UploadedTrackList;
