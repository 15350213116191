import React from 'react';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import GlobalStyle from './styles/global';
import Signin from './features/Auth/Signin';
import PublicRoute from './services/PublicRoute';
import PrivateRoute from './services/PrivateRoute';
import ForgotPassword from './features/Auth/ForgotPassword';
import ResetPassword from './features/Auth/ResetPassword';
import SignupStepOne from './features/Auth/Signup/SignupStepOne';
import SignupStepTwo from './features/Auth/Signup/SignupStepTwo';
import SignupStepThree from './features/Auth/Signup/SignupStepThree';
import SelectPackage from './features/Purchase/SelectPackage';
import AddPayment from './features/Purchase/AddPayment/AddPayment';
import Summary from './features/Purchase/Summary/Summary';
import Dashboard from './features/Dashboard';
import MultiAsset from './features/Purchase/MultiAsset/MultiAsset';
import PageView from './components/PageView/PageView';
import URLInterceptor from './utils/URLInterceptor';
import Billing from './features/Billing/Billing';

const App: React.FC = () => (
  <Router>
    <URLInterceptor>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <PageView />
        <Routes>
          {/* Not Found */}
          <Route path="/*" element={<Navigate to="/" />} />
          {/* Private routes */}
          <Route path="/" element={<PrivateRoute component={Dashboard} />} />
          <Route
            path="/dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route
            path="/billing"
            element={<PrivateRoute component={Billing} />}
          />
          <Route path="payment">
            <Route
              path="multiasset"
              element={<PrivateRoute component={MultiAsset} />}
            />
            <Route
              path="selectpackage"
              element={<PrivateRoute component={SelectPackage} />}
            />
            <Route
              path="addpayment"
              element={<PrivateRoute component={AddPayment} />}
            />
            <Route
              path="summary"
              element={<PrivateRoute component={Summary} />}
            />
            {/* TODO: enterprise implementation with API */}
            {/* <Route
              path="enterprise/summary"
              element={<PrivateRoute component={SummaryEnterprise} />}
            /> */}
          </Route>
          <Route path="auth">
            <Route
              path="signup"
              element={<PublicRoute component={SignupStepOne} />}
            />
            <Route
              path="signupsteptwo"
              element={<PublicRoute component={SignupStepTwo} />}
            />
            <Route
              path="signupstepthree"
              element={<PublicRoute component={SignupStepThree} />}
            />
            <Route path="signin" element={<PublicRoute component={Signin} />} />
            <Route
              path="forgot_password"
              element={<PublicRoute component={ForgotPassword} />}
            />
            <Route
              path="reset_password/:key"
              element={<PublicRoute component={ResetPassword} />}
            />
          </Route>
        </Routes>
      </ThemeProvider>
    </URLInterceptor>
  </Router>
);

export default App;
