import {
  Banner,
  Icon,
  MaterialIcon,
  PaymentIcon,
  Separator,
  SpinningIcon,
  Text,
  Tooltip,
} from '@dsny/dsny-component-library';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'src/app/store';
import { v4 as uuid } from 'uuid';
import theme from 'src/styles/theme';
import { format } from 'date-fns-tz';
import { TrackingStatusEnum } from 'src/features/Dashboard';
import { getSessionUser } from 'src/features/Auth';
import {
  convertDateToUTC,
  makeFirstLetterUpperCase,
} from 'src/utils/Formatter';
import { IconNames } from '@dsny/dsny-component-library/dist/components/Tonality/Icons/PaymentIcon/PaymentIcon';
import {
  AddPaymentObj,
  getCurrentPaymentMethod,
  getPurchaseCheckout,
  getPurchaseExtension,
  getPurchaseRenewal,
  PurchaseCheckoutList,
  resetPaymentPrepate,
} from '../AddPayment';
import {
  Container,
  ErrorWrapper,
  FormButton,
  PurchaseWrapper,
} from '../Purchase.styles';
import { PurchaseCancelModal } from '../PurchaseCancelModal';
import { PurchaseSuccessModal } from '../PurchaseSuccessModal';
import {
  BilllingInfo,
  ClickableGoBack,
  FeesWrapper,
  GoBackWrapper,
  IconWrapper,
  LockIconWrapper,
  PayButton,
  PromoPriceWrapper,
  RegularPriceWrapper,
  StripeNote,
  StripeWrapper,
  SummaryBox,
  SummaryButtonWrapper,
  SummaryCardWrapper,
  SummaryContainer,
  SummaryInfo,
  SummaryInfoWrapper,
  SummaryTitle,
  TooltipWrapper,
} from './Summary.styles';
import { SummaryDeleteSongModal } from './SummaryDeleteSongModal';
import { AddTrackList } from '../MultiAsset';
import { PlanWrapper } from '../SelectPackage/SelectPackage.styles';
import UploadedTrackList from './UploadedTrackList/UploadedTrackList';
import { trackEvent } from '../../../utils/segmentService';
import { ImportReleaseSummaryDeleteModal } from '../MultiAsset/ImportRelease/ImportReleaseSummaryDeleteModal';
import { ImportReleaseCancelModal } from '../MultiAsset/ImportRelease/ImportReleaseCancelModal';
import DiscountCode from './DiscountCode/DiscountCode';

const Summary: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showCancel, setShowCancel] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleteSong, setShowDeleteSong] = useState(false);
  const [showDeleteSongRelease, setShowDeleteSongRelease] = useState(false);
  const [songId, setSongId] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const { addPayment, selectPackage } = useSelector((state: RootState) => ({
    addPayment: state.addPayment,
    selectPackage: state.selectPackage,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isFetching) {
      setIsFetching(addPayment.isFetching);
    }
  }, [addPayment.isFetching]);

  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const { promoCodeActive } = useSelector(
    (state: RootState) => state.selectPackage
  );

  const { tracks, releaseTrack }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  const { hasError: paymentError, hasPaymentError }: AddPaymentObj =
    useSelector((state: RootState) => state.addPayment);

  const { currentPaymentMethod, purchaseCheckout } = addPayment;

  useEffect(() => {
    if (!currentPaymentMethod?.id) {
      dispatch(getCurrentPaymentMethod(true));
    }
  }, [currentPaymentMethod]);

  useEffect(() => {
    if (!tracks?.length && !selectedSong) {
      navigate('/payment/multiasset');
    }

    if (!selectPackage?.selectedPackage) {
      navigate('/payment/selectpackage');
    }
  }, []);

  // Generates purchase id
  const generatePurchaseId = () => {
    return uuid().replace(/-/gi, '');
  };

  useEffect(() => {
    setIsFetching(false);
    if (purchaseCheckout.isPurchaseSuccessful) {
      setShowSuccess(true);
    }
  }, [purchaseCheckout]);

  // Format date for server
  const formatDateForServer = (startTrackingDate: Date) => {
    return format(convertDateToUTC(new Date(startTrackingDate)), 'yyyy-MM-dd');
  };

  // Getting checkout items
  const getCheckOutItems = () => {
    return tracks.map((track) => {
      return {
        artist: track.artist,
        title: track.title,
        upload_s3_bucket: track.S3File?.bucket || null,
        upload_s3_key: track.S3File?.key || null,
        src_filename: track.file?.name || null,
        track_id: parseInt(track.track_id, 10),
        start_date: formatDateForServer(track.startTrackingDate || new Date()),
        version: track.releaseTrack?.version || null,
      };
    });
  };

  // Getting payment method
  const getPaymentMethod = () => {
    return addPayment.cardManagement.isCardNew
      ? {
          is_new: true,
          id: addPayment?.currentPaymentMethod?.id,
          save_for_later: addPayment?.cardManagement.shouldSaveCard,
        }
      : undefined;
  };

  // Handling pay button
  const successBtnHandler = () => {
    if (isFetching) return;
    const purchaseId = generatePurchaseId();
    setIsFetching(true);
    const paymentMethod = getPaymentMethod();
    if (tracks.length > 0) {
      dispatch(
        getPurchaseCheckout({
          identifier: purchaseId,
          release_id: releaseTrack?.release_id || '',
          plan_id: selectPackage.selectedPackage?.plan?.id,
          promo_code: promoCodeActive,
          items: getCheckOutItems(),
          payment_method: paymentMethod,
        } as PurchaseCheckoutList)
      ).then(() => {
        trackEvent('Purchase', {
          purchase_type: 'new',
          release_id: releaseTrack?.release_id || '',
          items_purchased: tracks.length.toString(),
          plan_id: selectPackage.selectedPackage?.plan?.id || '',
          plan_name: selectPackage.selectedPackage?.plan?.name || '',
          is_new_payment_method:
            paymentMethod !== undefined
              ? paymentMethod.is_new.toString()
              : 'false',
          card_save_for_later:
            paymentMethod !== undefined
              ? paymentMethod.save_for_later.toString()
              : 'n/a',
          total_due_today:
            selectPackage.selectedPackage?.summary?.total || '0.00',
        });
      });
    } else if (
      selectedSong?.selected_tracking_period.status ===
      TrackingStatusEnum.PAST_EXPIRED
    ) {
      const { company_id } = getSessionUser();
      dispatch(
        getPurchaseRenewal({
          identifier: purchaseId,
          company_id: company_id.toString(),
          song_id: selectedSong.song_id,
          plan_id: selectPackage.selectedPackage?.plan?.id || '',
          payment_method: paymentMethod,
        })
      ).then(() => {
        trackEvent('Purchase', {
          purchase_type: 'renewal',
          release_id: '',
          items_purchased: tracks.length.toString(),
          plan_id: selectPackage.selectedPackage?.plan?.id || '',
          plan_name: selectPackage.selectedPackage?.plan?.name || '',
          is_new_payment_method:
            paymentMethod !== undefined
              ? paymentMethod.is_new.toString()
              : 'false',
          card_save_for_later:
            paymentMethod !== undefined
              ? paymentMethod.save_for_later.toString()
              : 'n/a',
          total_due_today:
            selectPackage.selectedPackage?.summary?.total || '0.00',
        });
      });
    } else {
      const { company_id } = getSessionUser();
      if (selectedSong) {
        dispatch(
          getPurchaseExtension({
            identifier: purchaseId,
            company_id: company_id.toString(),
            song_id: selectedSong.song_id,
            plan_id: selectPackage.selectedPackage?.plan?.id || '',
            payment_method: paymentMethod,
          })
        ).then(() => {
          trackEvent('Purchase', {
            purchase_type: 'extension',
            release_id: '',
            items_purchased: tracks.length.toString(),
            plan_id: selectPackage.selectedPackage?.plan?.id || '',
            plan_name: selectPackage.selectedPackage?.plan?.name || '',
            is_new_payment_method:
              paymentMethod !== undefined
                ? paymentMethod.is_new.toString()
                : 'false',
            card_save_for_later:
              paymentMethod !== undefined
                ? paymentMethod.save_for_later.toString()
                : 'n/a',
            total_due_today:
              selectPackage.selectedPackage?.summary?.total || '0.00',
          });
        });
      }
    }
  };

  // Handling delete song
  const deleteSong = (id: string, isRelease: boolean) => {
    setSongId(id);
    if (isRelease) {
      setShowDeleteSongRelease(true);
    } else {
      setShowDeleteSong(true);
    }
  };

  // Tooltip text
  const feesTooltip = t('SUMMARY_FEES_TOOLTIP');

  // Unable to complete transaction banner
  const serverErrorMessage = {
    title: t('SUMMARY_ERROR_BANNER_TITLE'),
    description: t('SUMMARY_ERROR_BANNER_DESC'),
  };

  // Handling go back button
  const handleGoBackButton = () => {
    dispatch(resetPaymentPrepate());
    navigate('/payment/addpayment');
  };

  return (
    <PurchaseWrapper>
      <Container>
        <SummaryBox>
          {/* Banner messages */}
          {(paymentError || addPayment.hasError || hasPaymentError) && (
            <ErrorWrapper>
              <Banner
                bannerContent={serverErrorMessage}
                variant="error"
                width="100%"
              />
            </ErrorWrapper>
          )}

          {/* Go back button */}
          <GoBackWrapper>
            <ClickableGoBack
              onClick={() => {
                handleGoBackButton();
              }}
            >
              <Icon name="ArrowLeft" />
              {t('SUMMARY_GO_BACK_BUTTON')}
            </ClickableGoBack>
          </GoBackWrapper>

          {/* Title */}
          <SummaryTitle>{t('SUMMARY_TITTLE')}</SummaryTitle>

          {/* Uploaded track list */}
          <UploadedTrackList deleteSong={deleteSong} />

          {/* Card details */}
          <Text
            fontSize="16px"
            fontWeight={700}
            color={theme.colors.neutralW20}
            style={{ paddingTop: '8px', paddingBottom: '8px' }}
          >
            {t('SUMMARY_CARD_DETAILS')}
          </Text>
          <SummaryContainer>
            <SummaryCardWrapper>
              {/* Credit Card Icon */}
              <PaymentIcon
                name={
                  makeFirstLetterUpperCase(
                    currentPaymentMethod?.brand
                  ) as keyof typeof IconNames
                }
              />
              <SummaryInfo>
                **** {currentPaymentMethod.last4}
                <p>
                  {t('SUMMARY_EXPIRY')}
                  {currentPaymentMethod.exp_month}/
                  {currentPaymentMethod.exp_year}{' '}
                </p>
              </SummaryInfo>
            </SummaryCardWrapper>
          </SummaryContainer>

          {/* Plan and billing details */}
          <Text
            fontSize="16px"
            fontWeight={700}
            color={theme.colors.neutralW20}
            style={{ paddingBottom: '8px' }}
          >
            {t('SUMMARY_BILLING')}
          </Text>
          <SummaryContainer>
            {/* Tracking plan */}
            <SummaryInfoWrapper>
              <Text
                fontSize="14px"
                fontWeight={400}
                color={theme.colors.neutralW20}
              >
                {t('SUMMARY_TRACKING_PLAN')}
              </Text>

              {/* Unit price */}
              <Text
                fontSize="16px"
                fontWeight={400}
                color={theme.colors.neutralW20}
              >
                $
                {Number(
                  selectPackage.selectedPackage?.summary.unit_price
                ).toFixed(2)}
              </Text>
            </SummaryInfoWrapper>

            {/* Selected plan package */}
            <SummaryInfoWrapper>
              <BilllingInfo>
                <RegularPriceWrapper>
                  <PlanWrapper>
                    <Text
                      fontSize="12px"
                      fontWeight={700}
                      color={theme.colors.neutralW40}
                    >
                      {selectPackage.selectedPackage?.plan?.description}
                    </Text>
                    &nbsp;
                    <Text
                      fontSize="12px"
                      fontWeight={400}
                      color={theme.colors.neutralW40}
                    >
                      {t('SUMMARY_OF_RADIO_TRACKING')}
                    </Text>
                  </PlanWrapper>
                  &nbsp;
                  {/* Discount for the selected plan package when applicable */}
                  {selectPackage.selectedPackage &&
                    !selectPackage.selectedPackage?.promotion &&
                    Number(selectPackage.selectedPackage.plan.discount) > 0 && (
                      <Text
                        fontSize="12px"
                        fontWeight={700}
                        color={theme.colors.neutralW40}
                      >
                        - {t('SUMMARY_SAVE')}&nbsp;
                        {Number(selectPackage.selectedPackage?.plan?.discount) *
                          100}
                        %
                      </Text>
                    )}
                </RegularPriceWrapper>
              </BilllingInfo>
            </SummaryInfoWrapper>

            {/* Number of songs */}
            <SummaryInfoWrapper>
              <Text
                fontSize="14px"
                fontWeight={400}
                color={theme.colors.neutralW20}
                style={{ marginTop: '16px' }}
              >
                {t('SUMMARY_NUMBER_OF_SONGS')}
              </Text>
              <Text
                fontSize="16px"
                fontWeight={400}
                color={theme.colors.neutralW20}
                style={{ marginTop: '16px' }}
              >
                {selectedSong && tracks.length === 0 ? 1 : tracks.length}
              </Text>
            </SummaryInfoWrapper>

            {/* Discount code */}
            <DiscountCode />

            {/* Separator */}
            <Separator margin="0px" />

            {/* Subtotal */}
            <SummaryInfoWrapper>
              <Text
                fontSize="14px"
                fontWeight={400}
                color={theme.colors.neutralW20}
                style={{ paddingTop: '16px' }}
              >
                {t('SUMMARY_SUBTOTAL')}
              </Text>
              <Text
                fontSize="16px"
                fontWeight={400}
                color={theme.colors.neutralW20}
                style={{ paddingTop: '16px' }}
              >
                $
                {Number(
                  selectPackage.selectedPackage?.summary?.subtotal
                ).toFixed(2)}
              </Text>
            </SummaryInfoWrapper>

            {/* Discount */}
            {selectPackage.selectedPackage &&
              selectPackage.selectedPackage?.promotion &&
              Number(
                selectPackage.selectedPackage.summary.promo_discount_amount
              ) > 0 && (
                <>
                  <SummaryInfoWrapper>
                    <Text
                      fontSize="14px"
                      fontWeight={400}
                      color={theme.colors.neutralW20}
                      style={{ paddingTop: '16px' }}
                    >
                      {t('SUMMARY_DISCOUNT')}
                    </Text>
                    {/* Discount amount */}
                    <Text
                      fontSize="16px"
                      fontWeight={400}
                      color={theme.colors.error50}
                      style={{ paddingTop: '16px' }}
                    >
                      – $
                      {Number(
                        selectPackage.selectedPackage.summary
                          .promo_discount_amount
                      ).toFixed(2)}
                    </Text>
                  </SummaryInfoWrapper>

                  <SummaryInfoWrapper>
                    <BilllingInfo>
                      {promoCodeActive ? (
                        // Promo code scenario
                        <PromoPriceWrapper>
                          <Text
                            fontSize="12px"
                            fontWeight={700}
                            color={theme.colors.neutralW40}
                          >
                            <Trans
                              i18nKey="SUMMARY_SAVE_DISCOUNT_CODE"
                              values={{
                                discount: `${
                                  Number(
                                    selectPackage.selectedPackage?.promotion
                                      ?.discount_percentage
                                  ) * 100
                                }`,
                              }}
                              components={{ bold: <strong /> }}
                            />
                          </Text>
                        </PromoPriceWrapper>
                      ) : (
                        // Plan package scenario
                        <PromoPriceWrapper>
                          <Text
                            fontSize="12px"
                            fontWeight={400}
                            color={theme.colors.neutralW40}
                          >
                            {t('SUMMARY_SAVE')}
                          </Text>
                          &nbsp;
                          <Text
                            fontSize="12px"
                            fontWeight={700}
                            color={theme.colors.neutralW40}
                          >
                            {Number(
                              selectPackage.selectedPackage?.promotion
                                ?.discount_percentage
                            ) * 100}
                            %
                          </Text>
                          &nbsp;
                          <Text
                            fontSize="12px"
                            fontWeight={700}
                            color={theme.colors.neutralW40}
                          >
                            - {selectPackage.selectedPackage?.promotion?.name}
                          </Text>
                        </PromoPriceWrapper>
                      )}
                    </BilllingInfo>
                  </SummaryInfoWrapper>
                </>
              )}

            {/* Taxes & processing fees */}
            <SummaryInfoWrapper>
              <FeesWrapper>
                <Text
                  fontSize="14px"
                  fontWeight={400}
                  color={theme.colors.neutralW20}
                  style={{ paddingTop: '16px', paddingBottom: '16px' }}
                >
                  <TooltipWrapper>
                    {t('SUMMARY_TAXES_FEES')}
                    <Tooltip
                      text={feesTooltip}
                      placement="top"
                      color={theme.colors.white}
                      backgroundColor={theme.colors.neutralW50}
                      hasArrow={false}
                      containerStyles={{
                        display: 'block',
                        width: '200px',
                        whiteSpace: 'normal',
                        fontFamily: theme.fonts.primary,
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '16px',
                        borderRadius: '5px',
                        textAlign: 'left',
                      }}
                    >
                      <IconWrapper>
                        <MaterialIcon
                          name="Info"
                          color={theme.colors.neutralW20}
                        />
                      </IconWrapper>
                    </Tooltip>
                  </TooltipWrapper>
                </Text>
              </FeesWrapper>
              <Text
                fontSize="16px"
                fontWeight={400}
                color={theme.colors.neutralW20}
                style={{ paddingTop: '16px', paddingBottom: '16px' }}
              >
                $
                {Number(
                  selectPackage.selectedPackage?.summary?.taxes_and_fees
                ).toFixed(2)}
              </Text>
            </SummaryInfoWrapper>

            {/* Separator */}
            <Separator margin="0px" />

            {/* Total due today */}
            <SummaryInfoWrapper>
              <Text
                fontSize="14px"
                fontWeight={700}
                color={theme.colors.neutralW20}
                style={{ paddingTop: '16px' }}
              >
                {t('SUMMARY_TOTAL_DUE_TODAY')}
              </Text>
              <Text
                fontSize="16px"
                fontWeight={700}
                color={theme.colors.neutralW20}
                style={{ paddingTop: '16px' }}
              >
                {t('SUMMARY_US')}&nbsp;$
                {Number(selectPackage.selectedPackage?.summary?.total).toFixed(
                  2
                )}
              </Text>
            </SummaryInfoWrapper>
          </SummaryContainer>

          {/* Summary buttons */}
          <SummaryButtonWrapper>
            {/* Cancel button */}
            <FormButton
              setting="mtr"
              variant="tertiary"
              type="button"
              onClick={() => setShowCancel(true)}
            >
              {t('PURCHASE_CANCEL_BUTTON')}
            </FormButton>

            {/* Pay button */}
            <PayButton
              setting="mtr"
              type="button"
              disabled={hasPaymentError || isFetching}
              onClick={successBtnHandler}
              style={{
                paddingTop: isFetching ? '14px' : '',
              }}
            >
              {isFetching ? (
                <SpinningIcon>
                  <MaterialIcon name="Rotate" />
                </SpinningIcon>
              ) : (
                <LockIconWrapper>
                  <MaterialIcon name="Locked" color={theme.colors.white0} />
                  &nbsp;&nbsp;
                  <Trans
                    i18nKey="PURCHASE_PAYMENT_PAY"
                    values={{
                      price: `${Number(
                        selectPackage.selectedPackage?.summary?.total
                      ).toFixed(2)}`,
                    }}
                    components={{ bold: <strong /> }}
                  />
                </LockIconWrapper>
              )}
            </PayButton>
          </SummaryButtonWrapper>

          {/* Stripe note */}
          <StripeWrapper>
            <StripeNote>
              <Trans
                i18nKey="SUMMARY_STRIPE_NOTE"
                components={{ bold: <strong /> }}
              />
            </StripeNote>
            <PaymentIcon name="StripeLogo" />
          </StripeWrapper>
        </SummaryBox>

        {/* Cancel Modal */}
        {/* 2. Caster release cancel modal */}
        {releaseTrack.release_id ? (
          <ImportReleaseCancelModal
            show={showCancel}
            setShowReleaseCancelModal={setShowCancel}
          />
        ) : (
          /* 1. Default cancel modal */
          <PurchaseCancelModal
            show={showCancel}
            setShowCancel={setShowCancel}
          />
        )}

        {/* Success Modal */}
        <PurchaseSuccessModal
          show={showSuccess}
          setShowSuccess={setShowSuccess}
        />

        {/* Delete Modal */}
        {/* 1. Default Delete Song Modal */}
        <SummaryDeleteSongModal
          show={showDeleteSong}
          setShowCancel={setShowDeleteSong}
          songId={songId}
          numberOfTracks={tracks.length}
          selectedPackage={selectPackage.selectedPackage}
        />

        {/* 2. Caster Release Delete Song Modal */}
        <ImportReleaseSummaryDeleteModal
          show={showDeleteSongRelease}
          setShowCancel={setShowDeleteSongRelease}
          songId={songId}
          numberOfTracks={tracks.length}
          selectedPackage={selectPackage.selectedPackage}
        />
      </Container>
    </PurchaseWrapper>
  );
};

export default Summary;
