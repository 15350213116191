/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { noop } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { useNavigate } from 'react-router-dom';
import {
  FlexButtonWrapper,
  ModalGoToDashboardButton,
  ModalPurchaseSucessBody,
  ModalPurchaseSucessHeading,
} from './Purchase.styles';
import { resetStateAddPayment } from './AddPayment';
import { AddTrackList, resetStateAddTrack } from './MultiAsset';
import { resetStateSelectPackage } from './SelectPackage';
import {
  clearSongSelection,
  resetStateDashBoard,
  TrackingStatusEnum,
} from '../Dashboard';
import { trackEvent } from '../../utils/segmentService';

export const PurchaseSuccessModal = ({ show, setShowSuccess }: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      trackEvent('Modal View', { modal_type: 'purchase_successful_modal' });
    }
  }, [show]);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { tracks }: AddTrackList = useSelector(
    (state: RootState) => state.multiAsset
  );

  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  const handleSuccessModal = () => {
    dispatch(resetStateAddPayment());
    dispatch(resetStateAddTrack());
    dispatch(resetStateSelectPackage());
    dispatch(clearSongSelection());
    dispatch(resetStateDashBoard());
    setShowSuccess(false);
    navigate('/dashboard');
  };

  // Getting modal text based on 3 scenarios (Renewal - Extension - Default)
  const modalText = () => {
    /* Renew state - expired past 90 day period */
    if (
      !tracks ||
      (tracks.length === 0 &&
        selectedSong?.selected_tracking_period.status ===
          TrackingStatusEnum.PAST_EXPIRED)
    ) {
      return (
        <Trans
          i18nKey="PURCHASE_SUCESS_MODAL_RENEW_DESC"
          values={{ song: selectedSong?.title }}
          components={{ bold: <strong /> }}
        />
      );
    }

    /* Extend state - within 90 day period */
    if (!tracks || tracks.length === 0) {
      return (
        <Trans
          i18nKey="PURCHASE_SUCESS_MODAL_EXTEND_DESC"
          values={{ song: selectedSong?.title }}
          components={{ bold: <strong /> }}
        />
      );
    }
    /* Default state */
    return <div>{t('PURCHASE_SUCESS_MODAL_DESC')}</div>;
  };

  const { isEnterpriseAccount } = useSelector(
    (state: RootState) => state.dashboard
  );

  // Getting modal title based on 2 scenarios default and enterprise
  // TODO: enterprise implementation with API
  const modalTitle = () => {
    return (
      <div>
        {isEnterpriseAccount
          ? // Enterprise title
            t('ENTERPRISE_PURCHASE_SUCCESS_MODAL_TITLE')
          : // Default title
            t('PURCHASE_SUCCESS_MODAL_TITLE')}
      </div>
    );
  };

  return (
    <Modal
      isOpen={show}
      onClose={noop}
      containerStyles={{
        width: '608px',
        padding: '32px',
      }}
    >
      <ModalPurchaseSucessHeading>{modalTitle()}</ModalPurchaseSucessHeading>

      <ModalPurchaseSucessBody>{modalText()}</ModalPurchaseSucessBody>

      <FlexButtonWrapper>
        <ModalGoToDashboardButton
          setting="mtr"
          type="button"
          onClick={handleSuccessModal}
        >
          {t('PURCHASE_SUCESS_MODAL_GO_TO_DASHBOARD_BUTTON')}
        </ModalGoToDashboardButton>
      </FlexButtonWrapper>
    </Modal>
  );
};
