import React from 'react';

import {
  LogoMTRFullWhite,
  FaviconMTRWhite,
  LogoMTREnterpriseFullWhite,
  FaviconMTREnterpriseWhite,
} from '@dsny/dsny-component-library';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import { LogoWrapper, NavLinkWrapper } from './Sidebar.styles';

type SidebarFooterProp = {
  to: string;
  isExpanded: boolean;
  onClick: () => void;
};

const SidebarLogo = ({ isExpanded, onClick }: SidebarFooterProp) => {
  const { isEnterpriseAccount } = useSelector(
    (state: RootState) => state.dashboard
  );
  return (
    <>
      <LogoWrapper $isExpanded={isExpanded}>
        <NavLinkWrapper tabIndex={0} to="" onClick={onClick}>
          {/* Desktop enterprise logo */}
          {/* TODO: enterprise implementation with API */}
          {isEnterpriseAccount && isExpanded && <LogoMTREnterpriseFullWhite />}
          {isEnterpriseAccount && !isExpanded && <FaviconMTREnterpriseWhite />}

          {/* Desktop default logo */}
          {!isEnterpriseAccount && isExpanded && <LogoMTRFullWhite />}
          {!isEnterpriseAccount && !isExpanded && <FaviconMTRWhite />}
        </NavLinkWrapper>
      </LogoWrapper>
    </>
  );
};

export default SidebarLogo;
