/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AttachmentTile } from '@dsny/dsny-component-library';
import {
  DesktopWrapper,
  MobileWrapper,
} from './EnterpriseExtendSongModal.styles';

export const EnterpriseSelectedSong = () => {
  return (
    <>
      {/* Desktop view */}
      <DesktopWrapper>
        <AttachmentTile
          setting="neutral"
          {...{
            attachment: {
              id: '1',
              uploaduuid: '',
              filename: 'File name',
              title: 'Song title',
              size: 0,
              type: 'audio/wav',
            },
            uploadedFiles: {},
            // eslint-disable-next-line no-console
            onDelete: () => console.log('delete'),
          }}
          displayActionIcon={false}
          text="Artist name | Song title"
          subText="Tracking period: 04 January, 2025 - 04 February, 2025" // TODO: Tracking Period: [end date] - [1 month from end date]
          isUploading={false}
        />
      </DesktopWrapper>

      {/* Mobile view */}
      <MobileWrapper>
        <AttachmentTile
          setting="neutral"
          {...{
            attachment: {
              id: '1',
              uploaduuid: '',
              filename: 'File name',
              title: 'Song title',
              size: 0,
              type: 'audio/wav',
            },
            uploadedFiles: {},
            // eslint-disable-next-line no-console
            onDelete: () => console.log('delete'),
          }}
          displayActionIcon={false}
          text="Artist name | Song title"
          subText="01/04/25 - 02/04/25" // TODO: mm/dd/yy - mm/dd/yy (1 month from end date)
          isUploading={false}
        />
      </MobileWrapper>
    </>
  );
};
