/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Button } from '@dsny/dsny-component-library';
import {
  FlexButtonWrapper,
  ModalContainerStyles,
  ModalDescWrapper,
  ModalHeadingWrapper,
} from './EnterprisePlanLimitModal.styles';

export const EnterprisePlanLimitModal = ({ showModal, setShowModal }: any) => {
  const { t } = useTranslation();

  // Handling open zoho chat
  // TODO: when user click on contact us button the chat opens with business inquieres preselected
  const handleOpenZohoChat = () => {
    // eslint-disable-next-line no-console
    console.log('handleOpenZohoChat');
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      containerStyles={{ ...ModalContainerStyles }}
    >
      <ModalHeadingWrapper>
        {t('ENTERPRISE_PLAN_LIMIT_MODAL_TITLE')}
      </ModalHeadingWrapper>

      <ModalDescWrapper>
        <Trans
          i18nKey="ENTERPRISE_PLAN_LIMIT_MODAL_DESC"
          values={{ limit: 10 }} // TODO: enterprise implementation with API
          components={{ bold: <strong /> }}
        />
      </ModalDescWrapper>

      <FlexButtonWrapper>
        {/* Close button */}
        <Button
          setting="mtr"
          variant="tertiary"
          type="button"
          onClick={() => setShowModal(false)}
        >
          {t('ENTERPRISE_CLOSE_MODAL_BUTTON')}
        </Button>
        {/* Contact us button */}
        <Button
          setting="mtr"
          type="button"
          onClick={() => handleOpenZohoChat()}
        >
          {t('ENTERPRISE_CONTACT_US_MODAL_BUTTON')}
        </Button>
      </FlexButtonWrapper>
    </Modal>
  );
};
