/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Modal,
  Button,
  SpinningIcon,
  MaterialIcon,
} from '@dsny/dsny-component-library';
import {
  ExtendButton,
  FlexButtonWrapper,
  ModalBodyWrapper,
  ModalContainerStyles,
  ModalDescWrapper,
  ModalHeadingWrapper,
  SpinningStyleWrapper,
} from './EnterpriseExtendSongModal.styles';
import { EnterpriseSelectedSong } from './EnterpriseSelectedSong';

export const EnterpriseExtendSongModal = ({ showModal, setShowModal }: any) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFetching, setIsFetching] = useState(false);

  // Handling extend-renew button
  // TODO: enterprise implementation with API
  const handleExtendButton = () => {
    // eslint-disable-next-line no-console
    console.log('handleExtendButton');
  };

  // Getting description based on 3 scenarios
  // TODO: enterprise implementation with API
  const getEnterpriseText = () => {
    /* Scenario 1 (extend) - active, warning and day 1 of expiration */
    return (
      <>
        <Trans
          i18nKey="ENTERPRISE_EXTEND_ACTIVE_SONG_MODAL_DESC"
          values={{ enddate: 'Jan 04, 2025' }} // TODO: enterprise implementation with API
          components={{ bold: <strong /> }}
        />
      </>
    );

    /* Scenario 2 (extend) -  expired within 90 day period - starting day 2 of expiration until day 90  */
    // return (
    //   <>
    //     <Trans
    //       i18nKey="ENTERPRISE_EXTEND_EXPIRED_SONG_MODAL_DESC"
    //       values={{
    //         number: Math.abs(10), // TODO: enterprise implementation with API
    //         day: Math.abs(10) > 0 ? 'days' : 'day', // TODO: enterprise implementation with API
    //       }}
    //       components={{ bold: <strong /> }}
    //     />
    //   </>
    // );

    /* Scenario 3 (renew) -  expired past 90 day period - starting day 91  */
    // return <>{t('ENTERPRISE_RENEW_EXPIRED_SONG_MODAL_DESC')}</>;
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      containerStyles={{ ...ModalContainerStyles }}
    >
      <ModalHeadingWrapper>
        {t('ENTERPRISE_EXTEND_SONG_MODAL_TITLE')}
      </ModalHeadingWrapper>

      {/* TODO: get description based on 3 scenarios - check select package */}
      <ModalDescWrapper>{getEnterpriseText()}</ModalDescWrapper>

      <ModalBodyWrapper>
        {/* TODO: get selected song - check select package */}
        <EnterpriseSelectedSong />
      </ModalBodyWrapper>

      <FlexButtonWrapper>
        {/* Cancel button */}
        <Button
          setting="mtr"
          variant="tertiary"
          type="button"
          onClick={() => setShowModal(false)}
        >
          {t('ENTERPRISE_CANCEL_MODAL_BUTTON')}
        </Button>

        {/* Extend-Renew button */}
        <ExtendButton
          setting="mtr"
          type="button"
          onClick={() => handleExtendButton()}
        >
          {isFetching ? (
            <SpinningStyleWrapper>
              <SpinningIcon>
                <MaterialIcon name="Rotate" />
              </SpinningIcon>
            </SpinningStyleWrapper>
          ) : (
            t('ENTERPRISE_EXTEND_TRACKING_MODAL_BUTTON') // TODO: enterprise implementation with API - extend button
            // t('ENTERPRISE_RENEW_TRACKING_MODAL_BUTTON') // TODO: enterprise implementation with API - renew button
          )}
        </ExtendButton>
      </FlexButtonWrapper>
    </Modal>
  );
};
